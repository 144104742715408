.mind-page{
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 100;

    .mind-item{

        display: flex;
        width: 60%;
        flex-direction: column;
        align-items: center;
        

        .mind-quote,.mind-image{
            display: flex;
            justify-content: flex-start;
            height: 100%; 
        }

        .mind-quote{
                //padding: 2em;
                align-self: flex-start;
                text-align: left;
                white-space: nowrap;
                width: 500px;
        }

        .mind-image{
           height: 200px;
           img{
               object-fit: contain;
           }
        }

    }
}


 .mind-item:nth-of-type(odd) .mind-image{
    align-self: flex-start;
}

 .mind-item:nth-of-type(even) .mind-image{
    align-self: flex-end;
}