
@media only screen and (max-width: 600px) {

    #mute-btn{
        position: relative;
       top: -150px;

    }

    .contact-page .form-container{
        form{
            width: 70vw;
        }

    }


    .top-bar{
        position: fixed;
        top: 0;
        flex-wrap: wrap;
    
    
        ul{
            margin-right: 1em;
            text-transform: lowercase;
            display: flex;
            flex-wrap: wrap;
            justify-items: center;
    
        }
        
        li{
            width: 100%;
            font-size: 1.5em;
            margin-top: 10px;
        }
    
        h1{
            width: 100%;
            text-align: center;
            margin-left: auto;
            font-size: 1.5em;
        }
    
    }

    #menu{
        display: block;
        position: fixed;
        left: 2em;
        top: 24px;

    }

    .mobile-hidden{
        display: none !important;
    }

    .img-container{
        width: 80%;
        height: unset;
        margin-top: 1em;
        margin-bottom: 1em;
    }

    .app-body{
        margin-top: 5em;
    }

    .product-halfs{
        flex-direction: column;
        align-content: center;
        align-items: center;

        .product-image-container{
            display: none;
        }

        .product-display{
            width: 80%;
            img{
                width: 100%;
            }
            
        }

        .product-text-container{
            width: 80%;
            margin-bottom: 4em;
            p{
                width: 100%;
            }
        }
    }


}