.product-halfs{
    display: flex;
    justify-content: center;

}

.product-text-container{
    padding: 2em;
    width: 40%;

    .product-title{
        display: flex;
        align-items: center;

        h1{
            font-family: sans-serif;
            margin-right: 2em;
        }
        
        h2{
            font-family: serif;
            font-weight: normal;
        }
    }


    button{
        width: 100px;
        font-family: 'Baskervville', serif;
        display: block;
        border: 1px solid #fff;
        background: #000;
        color: #fff;
        font-size: 1em;
        padding: 5px;
        margin: 10px 0;
        user-select: none;
    }
    p{
        width: 60%;
        margin: 0;
        margin-bottom: 1em;
    }
}

.product-display{
    padding: 2em;
    img{
        width: 30vw;
    }
}

.product-image-container{
    display: flex;

    img{
        margin-right: 1em;
        height: 100px;
        width: 100px;
    }

}