.social-links{
    padding: 0;
    text-align: center;
    margin: 0;
    width: 100%;
    color: white;
     svg{
         position: relative;
         top: -1em;
         padding: 1em;
     }

    a,a:visited{
        color: white;
    }

}