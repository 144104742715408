.background-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
}

.background-item{
    width: 30%;
    margin-left: 1.5%;
    margin-right: 1.5%;
    height: 20vh;

    span{
        margin: 1em;
        font-size: .5em;
        display: block;
        text-align: left;
    }
}

.background-item:nth-of-type(5n){
    //background-color: white;
    //border: 1px solid white;
    color: black;
    //filter: invert(1);
}

.background-supercontainer{
    width: 100vw;
    margin-bottom: 200px;
}