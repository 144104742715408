.paint-page{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-content: center;
    align-items: center;
    

    iframe{
        display: block;
        width: 80%;
        height: 80%;
        margin: auto;
    }
}


.contact-page{
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    margin-bottom: 10vh;
}


.form-container{
    
    h2{
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 2em;
    }
    form{
        width: 30vw;
        input,textarea,button{
            font-family: 'Baskervville', serif;
            display: block;
            border: 1px solid #fff;
            background: #000;
            color: #fff;
            font-size: 1em;
            padding: 5px;
            width: 100%;
            margin: 10px 0;
            
        }

        button{
            width: 50%;
        }

        button:first-of-type{
            margin-bottom: 2em;
        }

        input:focus,textarea:focus{
            outline: none;
        }

        input::placeholder,textarea::placeholder{
            font-family: 'Baskervville', serif;
            color: white;
        }

        textarea{
            height: 150px;
        }


    }
}