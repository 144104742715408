@import url('https://fonts.googleapis.com/css?family=Baskervville&display=swap');

html, body{
  padding: 0;
  margin: 0;
  font-family: 'Baskervville', serif;
  box-sizing: border-box;
  overflow-x: hidden;
  background: black;
  color: white;
}

.app-body{
  min-height: 100vh;
}

.card{
  img{
    height: 200px;
  }
}

.row{
  //background-color: yellowgreen;
  width: 30%;
  //height: 35vh;
  margin-bottom: 2em;
  display: flex;
  //vertical-align: top;
  flex-direction: column;
  float: left;
  justify-content: flex-start;
  align-items: center;
}

.img-container,.image-loader{
  //height: 100%;
  //margin: 2em;
  width:  250px;
  height: 175px;
  margin: 3%;
  margin-top: 1px;
  //justify-self: flex-start;

  span{
    color: white;
    display: block;
    padding: 10px;
    font-family: courier;
    font-size: 12px;
  }
}

.vertical:nth-of-type(1){

}

.img-container:nth-of-type(3){
  img{
     object-position: bottom;
  }
 
}

img{
  height: 100%;
  //display: block;
  width: 100%;
  //margin: 2em;
  transform: scale(1);
  transition: all 2s ease;
  object-fit: cover;
  

}

.img-container{
  opacity: 1;
  transition: opacity .7s;
    img:hover, video:hover {
    z-index: 1000;
    transform: scale(1.5);
    transition: all 2s ease;

  }
}

.image-loader{
  opacity: .1;
}


.top-bar{

  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 1000;
  background: black;
  align-items: center;
  position: relative;
  text-transform: uppercase;

  padding-bottom: .5em;

  letter-spacing: 1px;


  ul{
    margin-right: 1em;
    text-transform: lowercase;

  }

  li,a{
    display: inline-block;
    color: white;
    margin-right: 1em;
    font-size: 1em;
    transition: all 1s ease-in-out;
    text-decoration: none;
    }

    li:hover,a:hover{
      transform: scale(1.1);
      text-decoration: underline;
    }

    a:visited{
      color: white;
      text-decoration: none;
    }

    h1{
      padding: 1em;
      color: white;
      text-align: left;
      margin: 0;
      font-size: 1em;
    }

}


.dossier-container{
  margin-bottom: 8em;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  
}

.color-container{
  position: relative;
  display: flex;
  top: -50px;

  div{
    width: 50%;
    height: 1em;
    background: black;
    border: 1px solid white;
    border-left: none;
  }

  div:nth-of-type(2){
    background: white;
  }
}

.top-video-cover,.bottom-video-cover{
  height: 3em;
  width: 100%;
  background-color: black;
  position: relative;
}


.bottom-video-cover{
  bottom: 3em;
}

.video-container{
  height: 100vh;
  width: 100%;
  margin: auto;
  position: relative;
  top: -6em;
}


.top-video-cover{
  top: 0;
  height: 2em;
}


/* MOBILE DEFAULTS */
.mobile-hidden{
  display: unset;
}

#menu{
  display: none;
}