

.mute-button{
    position: relative;
    top:  .5em;
    display: block;
    /* transform: rotate(-90deg); */
    width: 100px;
    text-align: center;
    text-transform: lowercase;
    border: 1px solid #fff;
    background: #000;
    color: #fff;
    font-size: 1em;
    padding: 5px;
    margin: auto;
    user-select: none;
}

button:focus {outline:0;}

